import React from "react";
import { useLanguage } from "../../contexts/LanguageContext";

const WhatWeOffers = () => {
  const { t } = useLanguage();

  const offersData = t("whatWeOffer"); // Fetch translations for "What We Offer"

  return (
    <section className="what-offers py-5">
      <div className="wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
          <div className="section-title text-center position-relative pb-3 mb-5 mx-auto">
            <h2 className="mb-0 display-5">{offersData.title}</h2>
          </div>

          <div className="row g-5">
            {offersData.items.map((offer, index) => (
              <div
                key={index}
                className="col-lg-6 col-md-6 wow zoomIn animated"
                data-wow-delay={`${0.3 + index * 0.3}s`}
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className={offer.icon + " text-white"}></i>
                  </div>
                  <h4 className="mb-3">{offer.title}</h4>
                  <p className="m-0">{offer.content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatWeOffers;