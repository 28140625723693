import React, { useEffect, useState } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import { useLanguage } from "../contexts/LanguageContext";
import InnerBanner from "../components/PagesComponents/InnerBanner";
import ProductSlider from "../components/Common/ProductSlider";
import { showToast } from "../utils/toast";
import { getApi, postApi } from "../utils/api/api";
import Pagination from "../utils/pagination";
import DashboardSidebar from "../components/DashboardComponents/DashboardSidebar";

const SellerBidDetail = () => {
  const baseUrl = "https://backend.tokoyarn.com/public/";
  const { id } = useParams();
  const location = useLocation();
  const { language } = useLanguage();
  const product = location.state?.bid || null;
  const [bids, setBids] = useState([]);
  const [activeTab, setActiveTab] = useState("product-details");
  const [currentPage, setCurrentPage] = useState(1);
  const [bidsPerPage] = useState(5);

  useEffect(() => {
    if (!product) {
      showToast("error", "Product not found!");
    }
  }, [product]);

  const handleApprove = async (bidId) => {
    try {
      await postApi(`seller/approve-bid/${bidId}`); // Separate API for approval
      setBids((prevBids) =>
        prevBids.map((bid) =>
          bid.id === bidId
            ? {
                ...bid,
                status: 1,
                isDisabled: true, // Disable buttons after action
              }
            : bid
        )
      );
      showToast("success", "Bid approved successfully!");
    } catch (error) {
      showToast("error", "Failed to approve bid!");
    }
  };

  const handleDecline = async (bidId) => {
    try {
      await postApi(`seller/decline-bid/${bidId}`); // Separate API for decline
      setBids((prevBids) =>
        prevBids.map((bid) =>
          bid.id === bidId
            ? {
                ...bid,
                status: 0,
                isDisabled: true, // Disable buttons after action
              }
            : bid
        )
      );
      showToast("success", "Bid declined successfully!");
    } catch (error) {
      showToast("error", "Failed to decline bid!");
    }
  };

  useEffect(() => {
    if (product) {
      fetchBids();
    }
  }, [product]);

  const fetchBids = async () => {
    try {
      const response = await getApi(`seller/product-detail/${id}`);
      const data = response?.data || {};
      console.log("BidData::", data);
      if (data.product.bids) {
        setBids(
          data.product.bids.map((bid) => ({
            ...bid,
            isDisabled: bid.status !== 2, // Disable if status is not Neutral
          }))
        );
      }
    } catch (error) {
      showToast("error", "Failed to load bids!");
    }
  };

  

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const formatKey = (key) => {
    if (!key) return null;
    return key
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
  };

  const indexOfLastBid = currentPage * bidsPerPage;
  const indexOfFirstBid = indexOfLastBid - bidsPerPage;
  const currentBids = bids.slice(indexOfFirstBid, indexOfLastBid);

  if (!product) return <div>Product not found!</div>;

  return (
    <>
      <InnerBanner pageKey="sellerBidDetails" />
      <section className="product-detail py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="row">
                <div className="col-lg-5">
                  {product.images && JSON.parse(product.images).length ? (
                    <ProductSlider images={product.images} />
                  ) : (
                    <p>No images available</p>
                  )}
                </div>
                <div className="col-lg-7">
                  <h2>{product.name}</h2>
                  <p>
                    <strong>Price:</strong> {product.price}
                  </p>
                  <h4>Description:</h4>
                  <p>{product.description}</p>
                </div>
              </div>

              <ul className="nav nav-tabs mt-4" id="productTabs" role="tablist">
                <li className="nav-item" role="presentation">
                  <a
                    className={`nav-link ${activeTab === "product-details" ? "active" : ""}`}
                    id="product-details-tab"
                    data-bs-toggle="tab"
                    href="#product-details"
                    role="tab"
                    onClick={() => setActiveTab("product-details")}
                  >
                    Product Details
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className={`nav-link ${activeTab === "bids" ? "active" : ""}`}
                    id="bids-tab"
                    data-bs-toggle="tab"
                    href="#bids"
                    role="tab"
                    onClick={() => setActiveTab("bids")}
                  >
                    Bids
                  </a>
                </li>
              </ul>

              <div className="tab-content mt-3" id="productTabsContent">
                <div
                  className={`tab-pane fade ${activeTab === "product-details" ? "show active" : ""}`}
                  id="product-details"
                  role="tabpanel"
                >
                  <div className="card p-4 bg-light mt-4">
                    <h2 className="title mb-4">Product Details</h2>
                    <table className="table table-bordered">
                      <tbody>
                      {Object.entries(product).map(([key, value]) => {
    // Skip keys: images, id, user_id, created_at, updated_at
    if (key === "images" || key === "id" || key === "user_id" || key === "created_at" || key === "updated_at" || key === "bids") {
      return null;
    }

    // Skip rendering if value is null, undefined, or an empty string
    if (value === null || value === undefined || value === "") {
      return null;
    }

    // Handle qty and qty_type concatenation under the key "quantity"
    if (key === "qty") {
      const qty = product.qty;
      const qtyType = product.qty_type;

      if (qty && qtyType) {
        const quantity = `${qty} ${qtyType}`;
        return (
          <tr key="quantity">
            <td>{formatKey("quantity")}</td>
            <td>{quantity}</td>
          </tr>
        );
      }
      return null; // Skip the row if qty or qty_type is missing
    }

    // Skip original `qty` and `qty_type` entries
    if (key === "qty_type") {
      return null;
    }

    // Skip if formatKey is null or empty
    const formattedKey = formatKey(key);
    if (!formattedKey) {
      return null;
    }

    // Handle certificate separately
    if (key === "certificate") {
      return (
        <tr key={key}>
          <td>{formattedKey}</td>
          <td>
            {value && value.length > 0 ? (
              <Link
                to={`${baseUrl}${value}`}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-secondary py-3 px-5"
              >
                Certificate
              </Link>
            ) : (
              <p>N/A</p>
            )}
          </td>
        </tr>
      );
    }

    // Render other rows if value is valid
    return (
      <tr key={key}>
        <td>{formattedKey}</td>
        <td>
          {typeof value === "object" && value !== null
            ? JSON.stringify(value)
            : value}
        </td>
      </tr>
    );
  })}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div
                  className={`tab-pane fade ${activeTab === "bids" ? "show active" : ""}`}
                  id="bids"
                  role="tabpanel"
                >
                  <div className="card p-4 bg-light mt-4">
                    <h2 className="title mb-4">Bids</h2>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Bid ID</th>
                          <th>Bid Value</th>
                          <th>User</th>
                          <th>Date</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentBids.length > 0 ? (
                          currentBids.map((bid) => (
                            <tr key={bid.id}>
                              <td>{bid.id}</td>
                              <td>{bid.value}</td>
                              <td>{bid.p_user_id}</td>
                              <td>{new Date(bid.created_at).toLocaleDateString()}</td>
                              <td>
                                {bid.status === 2
                                  ? ""
                                  : bid.status === 1 || bid.status == 'Approved'
                                  ? "Approved"
                                  : "Declined"}
                              </td>
                              <td>{ !bid.isDisabled ?
                              <div>
                                <button
                                  className="btn btn-success mr-2"
                                  onClick={() => handleApprove(bid.id)}
                                  disabled={bid.isDisabled}
                                >
                                  Approve
                                </button>
                                <button
                                  className="btn btn-danger"
                                  onClick={() => handleDecline(bid.id)}
                                  disabled={bid.isDisabled}
                                >
                                  Decline
                                </button>
                                </div> : ""
                                    }
                              </td>
                              
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6">No bids available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <Pagination
                      currentPage={currentPage}
                      totalPages={Math.ceil(bids.length / bidsPerPage)}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <DashboardSidebar />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SellerBidDetail;