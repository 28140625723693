import React, { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate, Link } from "react-router-dom";
import { useLanguage } from "../contexts/LanguageContext";
import InnerBanner from "../components/PagesComponents/InnerBanner";
import ProductSlider from "../components/Common/ProductSlider";
import { showToast } from "../utils/toast";
import { postApi } from "../utils/api/api";
import DashboardSidebar from "../components/DashboardComponents/DashboardSidebar";

const ProductDetail = () => {
  const baseUrl = "https://backend.tokoyarn.com/public/";
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { language } = useLanguage();
  const product = location.state?.product || null;
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("product-details");

  useEffect(() => {
    if (!product) {
      showToast("error", "Product not found!");
    }
  }, [product]);

  if (!product) return <div>Product not found!</div>;

  const handleBidSubmit = async (e) => {
    e.preventDefault();

    if (!value || isNaN(value) || value <= 0) {
      showToast("error", "Please enter a valid bid amount.");
      return;
    }

    setLoading(true);
    try {
      const payload = { value };
      const response = await postApi(`buyer/bid-product/${id}`, payload);

      if (response.success) {
        showToast("success", "Your bid has been submitted successfully!");
        navigate(`/buyer-dashboard/buyer-bids`);
      } else {
        showToast("error", "Failed to submit bid. Please try again.");
      }
    } catch (error) {
      showToast("error", error.message || "An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const formatKey = (key) => {
    if (!key) return null;
    return key
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <>
      <InnerBanner pageKey="productDetail" />
      <section className="product-detail py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="row">
                <div className="col-lg-5">
                  {product.images && JSON.parse(product.images).length ? (
                    <ProductSlider images={product.images} />
                  ) : (
                    <p>No images available</p>
                  )}
                </div>
                <div className="col-lg-7">
                  <h2>{product.name}</h2>
                  <p>
                    <strong>Price:</strong> {product.price}
                  </p>
                  <form onSubmit={handleBidSubmit} className="mb-4">
                    <label htmlFor="value" className="form-label">
                      Enter Bid Amount:
                    </label>
                    <input
                      type="number"
                      id="value"
                      className="form-control"
                      placeholder="Enter your bid"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                    />
                    <button type="submit" className="btn btn-primary mt-3" disabled={loading}>
                      {loading ? "Submitting..." : "Submit Bid"}
                    </button>
                  </form>
                </div>
              </div>

              <ul className="nav nav-tabs mt-4" id="productTabs" role="tablist">
                <li className="nav-item" role="presentation">
                  <a
                    className={`nav-link ${activeTab === "product-details" ? "active" : ""}`}
                    onClick={() => setActiveTab("product-details")}
                  >
                    Product Details
                  </a>
                </li>
                {/* <li className="nav-item" role="presentation">
                  <a
                    className={`nav-link ${activeTab === "bids" ? "active" : ""}`}
                    onClick={() => setActiveTab("bids")}
                  >
                    Bids
                  </a>
                </li> */}
              </ul>

              <div className="tab-content mt-3">
                <div
                  className={`tab-pane fade ${activeTab === "product-details" ? "show active" : ""}`}
                >
                  <div className="card p-4 bg-light mt-4">
                    <h2>Product Details</h2>
                    <table className="table table-bordered">
                      <tbody>
                      {Object.entries(product).map(([key, value]) => {
                          // Skip keys: images, id, user_id, created_at, updated_at
                          if (key === "images" || key === "id" || key === "user_id" || key === "created_at" || key === "updated_at" || key === "bids") {
                            return null;
                          }

                          // Skip rendering if value is null, undefined, or an empty string
                          if (value === null || value === undefined || value === "") {
                            return null;
                          }

                          // Handle qty and qty_type concatenation under the key "quantity"
                          if (key === "qty") {
                            const qty = product.qty;
                            const qtyType = product.qty_type;

                            if (qty && qtyType) {
                              const quantity = `${qty} ${qtyType}`;
                              return (
                                <tr key="quantity">
                                  <td>{formatKey("quantity")}</td>
                                  <td>{quantity}</td>
                                </tr>
                              );
                            }
                            return null; // Skip the row if qty or qty_type is missing
                          }

                          // Skip original `qty` and `qty_type` entries
                          if (key === "qty_type") {
                            return null;
                          }

                          // Skip if formatKey is null or empty
                          const formattedKey = formatKey(key);
                          if (!formattedKey) {
                            return null;
                          }

                          // Handle certificate separately
                          if (key === "certificate") {
                            return (
                              <tr key={key}>
                                <td>{formattedKey}</td>
                                <td>
                                  {value && value.length > 0 ? (
                                    <Link
                                      to={`${baseUrl}${value}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="btn btn-secondary py-3 px-5"
                                    >
                                      Certificate
                                    </Link>
                                  ) : (
                                    <p>N/A</p>
                                  )}
                                </td>
                              </tr>
                            );
                          }

                          // Render other rows if value is valid
                          return (
                            <tr key={key}>
                              <td>{formattedKey}</td>
                              <td>
                                {typeof value === "object" && value !== null
                                  ? JSON.stringify(value)
                                  : value}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div
                  className={`tab-pane fade ${activeTab === "bids" ? "show active" : ""}`}
                >
                  <div className="card p-4 bg-light mt-4">
                    <h2>Bids</h2>
                    <p>Bids data goes here...</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <DashboardSidebar />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductDetail;