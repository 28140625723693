import React from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from "../../contexts/LanguageContext";

const AdminTestimonialDetailInfo = () => {
const { t } = useLanguage();
  const location = useLocation();
  const testimonial = location.state?.testimonial || null; // Access the selected news item

  if (!testimonial) return <div>News item not found!</div>;

  const baseUrl = "https://backend.tokoyarn.com/public/";

  return (
    <div className="container py-5">
      <div className="com-header mb-4">
        <h2 className="text-primary">{t('adminTestimonialDeatil.title', 'Title')}</h2>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card p-3 shadow-sm">
            <div className="row">
              {/* News Image */}
              <div className="col-lg-4">
                {testimonial.image ? (
                  <img
                    src={`${baseUrl}${testimonial.image}`}
                    alt={testimonial.name}
                    className="img-fluid"
                  />
                ) : (
                  <p>No image available</p>
                )}
              </div>
              {/* News Details */}
              <div className="col-lg-8">
                <h4>{testimonial.name}</h4>
                <p>{testimonial.review}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminTestimonialDetailInfo;