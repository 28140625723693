

// src/pages/Home.jsx
import React from 'react';
import MainLayout from '../components/Layouts/MainLayout';
import InnerBanner from '../components/PagesComponents/InnerBanner';
import AdminLoginForm from '../components/Auth/AdminLoginForm';

const AdminLogin = () => (
  <MainLayout>
    <InnerBanner pageKey="adminLogin" />
     <AdminLoginForm/>
  </MainLayout>
);

export default AdminLogin;

