import React from 'react';
import DashboardSidebar from '../components/DashboardComponents/DashboardSidebar';
import AdminNewsList from '../components/AdminComponents/AdminNewsList';
import InnerBanner from '../components/PagesComponents/InnerBanner';
const AdminNews = () => {
 return (
    <>
    <InnerBanner pageKey="adminNews" />
      <section className='profile-sec py-5'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-9'>
                     <AdminNewsList/>
                </div>
                <div className='col-lg-3'>
                    <DashboardSidebar/>
                </div>
            </div>
        </div>
      </section>
    </>
 );

};

export default AdminNews;
