import React from 'react';
import DashboardSidebar from '../components/DashboardComponents/DashboardSidebar';
import InnerBanner from '../components/PagesComponents/InnerBanner';
import UsersList from '../components/AdminComponents/UserList';
const AdminDashboard = () => {
 return (
    <>
<InnerBanner pageKey="usersList" />

      <section className='dashboard py-4'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-9'>
                   <UsersList/> 
                </div>
                <div className='col-lg-3'>
                    <DashboardSidebar/>
                </div>
            </div>
        </div>
      </section>
    </>
 );

};

export default AdminDashboard;