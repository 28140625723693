import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { getApi } from "../../utils/api/api"; // Replace with the actual path to your API utility

const TestimonialSlider = () => {
  const [testimonials, setTestimonials] = useState([]);
  const baseUrl = "https://backend.tokoyarn.com/public/";

  // Fetch testimonials data
  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await getApi("/testimonial"); // Fetch from the testimonial API
        console.log("Testimonials Response:", response.data);

        if (response.data.testimonial && Array.isArray(response.data.testimonial)) {
          setTestimonials(response.data.testimonial);
        } else {
          console.error("Unexpected API response:", response);
          setTestimonials([]);
        }
      } catch (error) {
        console.error("Failed to fetch testimonials:", error);
        setTestimonials([]);
      }
    };

    fetchTestimonials();
  }, []);
  console.log("testimonials::", testimonials);

  return (
    <section className="testimonial-sec py-5">
      <div className="container">
        <div className="section-title text-center position-relative pb-0 mb-4 mx-auto">
          <h2 className="mb-0 display-5">What Our Clients Say</h2>
        </div>
        <div className="testimonial-slider">
          <Swiper
            modules={[Pagination]}
            spaceBetween={30}
            slidesPerView={1} // Default for mobile
            centeredSlides={true}
            loop={true}
            pagination={{ clickable: true }}
            breakpoints={{
              640: { slidesPerView: 1 }, // Mobile: 1 slide
              768: { slidesPerView: 2 }, // Tablets: 2 slides
              1024: { slidesPerView: 3 }, // Desktop: 3 slides
            }}
          >
            {testimonials.length > 0 ? (
              testimonials.map((testimonial, index) => (
                <SwiperSlide key={index}>
                  <div
                    className={`testimonial-card ${
                      index === 1 ? "center-slide" : ""
                    }`}
                  >
                    <img
                      src={`${baseUrl}${testimonial.image}`}
                      alt={testimonial.name || "Client Image"}
                      className="client-img"
                    />
                    <h3>{testimonial.name || "Anonymous"}</h3>
                    <p className="designation">
                      {testimonial.designation || "Client"}
                    </p>
                    <p className="review">{testimonial.review || ""}</p>
                  </div>
                </SwiperSlide>
              ))
            ) : (
              <p className="text-center">No testimonials available at the moment.</p>
            )}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSlider;