import React from 'react';
import Home from '../pages/Home';
import About from '../pages/About';
import Contact from '../pages/Contact';
import News from '../pages/News';
import NewsDetail from '../pages/NewsDetail';
import Login from '../pages/Login';
import Register from '../pages/Register';
import NotFound from '../pages/NotFound';
import ForgotPassword from '../pages/ForgotPassword';
import FirstTimePasswordChange from '../pages/FirstTimePasswordChange';
import Role from '../pages/Role';

import DashboardLayout from '../components/Layouts/DashboardLayout';
import SellerDashboard from '../userDashboardPages/SellerDashboard';
import BuyerDashboard from '../userDashboardPages/BuyerDashboard';
import InventoryDetail from '../userDashboardPages/InventoryDetail';

import Profile from '../userDashboardPages/Profile';
import ProductDetail from '../userDashboardPages/ProductDetail';
import AddInventory from '../userDashboardPages/AddInventory';
import EditInventory from '../userDashboardPages/EditInventory';
import SellerBid from '../userDashboardPages/SellerBid';
import BuyerBid from '../userDashboardPages/BuyerBid';
import BuyerProductDetail from '../userDashboardPages/BuyerProductDetail';

import AdminLogin from '../adminPages/AdminLogin';
import AdminDashboard from '../adminPages/AdminDashboard';
import AdminProductDetail from '../adminPages/AdminProductDetail';
import AdminProducts from '../adminPages/AdminProducts';
import AdminUserDetail from '../adminPages/AdminUserDetail';
import AdminNews from '../adminPages/AdminNews';
import AdminNewsCreate from '../adminPages/AdminNewsCreate';
import AdminNewsDetail from '../adminPages/AdminNewsDetail';

import ProtectedRoute from '../components/ProtectedRoute';
import AdminEditNews from '../adminPages/AdminEditNews';
import AdminTestimonial from '../adminPages/AdminTestimonial';
import AdminTestimonialCreate from '../adminPages/AdminTestimonialCreate';
import AdminEditTestimonial from '../adminPages/AdminEditTestimonial';
import AdminTestimonialDetail from '../adminPages/AdminTestimonialDetail';
import SellerBidDetail from '../userDashboardPages/SellerBidDetail';

// Public Routes
export const publicRoutes = [
  { path: '/', element: <Home /> },
  { path: '/about', element: <About /> },
  { path: '/contact', element: <Contact /> },
  { path: '/news', element: <News /> },
  { path: '/news-detail/:id', element: <NewsDetail /> },
  { path: '/login', element: <Login /> },
  { path: '/register', element: <Register /> },
  { path: '/forgot-password', element: <ForgotPassword /> },
  { path: '/admin-login', element: <AdminLogin /> },
  { path: '*', element: <NotFound /> },
];

// Protected Routes
export const protectedRoutes = [
  {
    path: '/first-time-password-change',
    element: <ProtectedRoute><FirstTimePasswordChange /></ProtectedRoute>,
  },
  {
    path: '/role',
    element: <ProtectedRoute><Role /></ProtectedRoute>,
  },
];

// Dashboard Routes with Role-Based Access
export const dashboardRoutes = [
  // User Role
  {
    path: '/seller-dashboard',
    element: (
      <ProtectedRoute requiredRole="user">
        <SellerDashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: '/seller-dashboard/add-inventory',
    element: (
      <ProtectedRoute requiredRole="user">
        <AddInventory />
      </ProtectedRoute>
    ),
  },
  {
    path: '/seller-dashboard/seller-bids',
    element: (
      <ProtectedRoute requiredRole="user">
        <SellerBid />
      </ProtectedRoute>
    ),
  },
  {
    path: '/seller-dashboard/seller-bids/:id',
    element: (
      <ProtectedRoute requiredRole="user">
        <SellerBidDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: '/seller-dashboard/:id',
    element: (
      <ProtectedRoute requiredRole="user">
        <EditInventory />
      </ProtectedRoute>
    ),
  },
  {
    path: '/seller-dashboard/inventory-detail/:id',
    element: (
      <ProtectedRoute requiredRole="user">
        <InventoryDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: '/buyer-dashboard',
    element: (
      <ProtectedRoute requiredRole="user">
        <BuyerDashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: '/buyer-dashboard/buyer-bids',
    element: (
      <ProtectedRoute requiredRole="user">
        <BuyerBid />
      </ProtectedRoute>
    ),
  },
  {
    path: '/buyer-dashboard/:id',
    element: (
      <ProtectedRoute requiredRole="user">
        <ProductDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: '/buyer-dashboard/product-detail/:id',
    element: (
      <ProtectedRoute requiredRole="user">
        <BuyerProductDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: '/profile',
    element: (
      <ProtectedRoute requiredRole="user">
        <Profile />
      </ProtectedRoute>
    ),
  },

  // Admin Role
  {
    path: '/admin/user-list',
    element: (
      <ProtectedRoute requiredRole="admin">
        <AdminDashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: '/admin/products/:id',
    element: (
      <ProtectedRoute requiredRole="admin">
        <AdminProductDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: '/admin/users/:id',
    element: (
      <ProtectedRoute requiredRole="admin">
        <AdminUserDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: '/admin/products',
    element: (
      <ProtectedRoute requiredRole="admin">
        <AdminProducts />
      </ProtectedRoute>
    ),
  },
  {
    path: '/admin/news',
    element: (
      <ProtectedRoute requiredRole="admin">
        <AdminNews />
      </ProtectedRoute>
    ),
  },
  {
    path: '/admin/news/create',
    element: (
      <ProtectedRoute requiredRole="admin">
        <AdminNewsCreate />
      </ProtectedRoute>
    ),
  },
  {
    path: '/admin/news/:id',
    element: (
      <ProtectedRoute requiredRole="admin">
        <AdminNewsDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: '/admin/news/edit/:id',
    element: (
      <ProtectedRoute requiredRole="admin">
        <AdminEditNews />
      </ProtectedRoute>
    ),
  },
  {
    path: '/admin/testimonials',
    element: (
      <ProtectedRoute requiredRole="admin">
        <AdminTestimonial />
      </ProtectedRoute>
    ),
  },
  {
    path: '/admin/testimonials/create',
    element: (
      <ProtectedRoute requiredRole="admin">
        <AdminTestimonialCreate />
      </ProtectedRoute>
    ),
  },
  {
    path: '/admin/testimonials/edit/:id',
    element: (
      <ProtectedRoute requiredRole="admin">
        <AdminEditTestimonial />
      </ProtectedRoute>
    ),
  },
  {
    path: '/admin/testimonials/:id',
    element: (
      <ProtectedRoute requiredRole="admin">
        <AdminTestimonialDetail />
      </ProtectedRoute>
    ),
  },
];