import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useLanguage } from "../contexts/LanguageContext";
import InnerBanner from "../components/PagesComponents/InnerBanner";
import ProductSlider from "../components/Common/ProductSlider";
import { showToast } from "../utils/toast";
import { getApi } from "../utils/api/api"; // Reuse the API utility function
import Pagination from "../utils/pagination"; // Reuse pagination component

const InventoryDetail = () => {
  const baseUrl = "https://backend.tokoyarn.com/public/";
  const { id } = useParams(); // Extract product ID from the route parameters
  const location = useLocation(); // Access location state
  const { language } = useLanguage(); // Get selected language
  const product = location.state?.inventory || null; // Get product data from location state
  const [bids, setBids] = useState([]);
  const [inventoryData, setInventoryData] = useState([]);
  const [activeTab, setActiveTab] = useState("product-details"); // To manage the active tab state
  const [currentPage, setCurrentPage] = useState(1);
  const [bidsPerPage] = useState(5); // Number of bids per page
  useEffect(() => {
    if (!product) {
      showToast("error", "Product not found!"); // Show error if product is missing
    }
  }, [product]);

  useEffect(() => {
    if (product) {
      // Fetch bids from API when product is available
      fetchBids();
    }
  }, [product]);

  const fetchBids = async () => {
    try {
      const response = await getApi(`seller/product-detail/${id}`);
      const data = response?.data || {};
      console.log("Bids::",response.data.product.bids);
      if (data.product) {
        console.log("InventoryData::",response.data.product);
        setInventoryData(data.product);
      }
      if (data.product.bids) {
        setBids(data.product.bids);
      }
    } catch (error) {
      showToast("error", "Failed to load bids!");
    }
  };

  // Function to format keys (e.g., created_at -> Create At)
  const formatKey = (key) => {
    return key
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastBid = currentPage * bidsPerPage;
  const indexOfFirstBid = indexOfLastBid - bidsPerPage;
  const currentBids = bids.slice(indexOfFirstBid, indexOfLastBid);

  // Create pagination items
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(bids.length / bidsPerPage); i++) {
    pageNumbers.push(i);
  }

  if (!product) return <div>Product not found!</div>;
//  console.log("inventoryData.images::", inventoryData.images);
  return (
    <>
      <InnerBanner pageKey="inventoryDetails" />
      <section className="product-detail py-5">
        <div className="container">
          <div className="row">
            {/* Left Column - Image Slider */}
            <div className="col-lg-4">
            {inventoryData.images && inventoryData.images.includes(",") ? (
  <ProductSlider images={inventoryData.images.split(",")} />
) : inventoryData.images ? (
  <ProductSlider images={[inventoryData.images]} />
) : (
  <p>No images available</p>
)}
            </div>

            {/* Right Column - Details */}
            <div className="col-lg-8">
              <h2>{product.name}</h2>
              <p>
                <strong>Price:</strong> {product.price}
              </p>
              <h4>Description:</h4>
              <p>{product.description}</p>
            </div>
          </div>

          {/* Tabs for Product Details and Bids */}
          <ul className="nav nav-tabs mt-4" id="productTabs" role="tablist">
            <li className="nav-item" role="presentation">
              <a
                className={`nav-link ${activeTab === "product-details" ? "active" : ""}`}
                id="product-details-tab"
                data-bs-toggle="tab"
                href="#product-details"
                role="tab"
                aria-controls="product-details"
                aria-selected={activeTab === "product-details"}
                onClick={() => setActiveTab("product-details")}
              >
                Product Details
              </a>
            </li>
          </ul>
          <div className="tab-content mt-3" id="productTabsContent">
            {/* Product Details Tab */}
            <div
              className={`tab-pane fade ${activeTab === "product-details" ? "show active" : ""}`}
              id="product-details"
              role="tabpanel"
              aria-labelledby="product-details-tab"
            >
              <div className="card p-4 bg-light mt-4">
                <h2 className="title mb-4">Product Details</h2>
                <table className="table table-bordered">
                  <tbody>
                  {Object.entries(product).map(([key, value]) => {
                      // Skip keys: images, id, user_id, created_at, updated_at
                      if (key === "images" || key === "id" || key === "user_id" || key === "created_at" || key === "updated_at" || key === "prevImages") {
                        return null;
                      }

                      // Handle certificate separately
                      if (key === "certificate") {
                        return (
                          <tr key={key}>
                            <td>{formatKey(key)}</td>
                            <td>
                              {value && value.length > 0 ? (
                                <img
                                  style={{ maxWidth: "200px" }}
                                  src={`${baseUrl}${value}`}
                                  alt={product.name}
                                  className="img-fluid"
                                />
                              ) : (
                                <p>N/A</p>
                              )}
                            </td>
                          </tr>
                        );
                      }

                      return (
                        <tr key={key}>
                          <td>{formatKey(key)}</td>
                          <td>
                            {typeof value === "object" && value !== null
                              ? JSON.stringify(value)
                              : value || "N/A"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            {/* Bids Tab */}
    
          </div>
        </div>
      </section>
    </>
  );
};

export default InventoryDetail;