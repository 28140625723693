import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLanguage } from "../../contexts/LanguageContext";
import { postApi } from '../../utils/api/api';
import { showToast } from '../../utils/toast';

const AdminEditNewsForm = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const { news } = location.state || {};
  console.log("news::>>", news);
  const baseUrl = "https://backend.tokoyarn.com/public/";

  const [formData, setFormData] = useState({
    title: '',
    image: null,
    video: '',
    description: '',
  });
  const [imagePreview, setImagePreview] = useState(null); // Preview for the current or uploaded image
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (news) {
      setFormData({
        title: news.title,
        image: null, // Will not prepopulate image, allow the user to upload a new one
        video: news.video_link || '',
        description: news.description,
      });
      setImagePreview(`${baseUrl}${news.image}` || null); // Load the existing image URL for preview
    }
  }, [news]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'image' && files[0]) {
      setImagePreview(URL.createObjectURL(files[0])); // Update the preview for the uploaded image
    }
    setFormData((prev) => ({
      ...prev,
      [name]: name === 'image' ? files[0] : value,
    }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const handleDeleteImage = () => {
    setFormData((prev) => ({ ...prev, image: null }));
    setImagePreview(null);
  };

  const validateForm = () => {
    const { title, description } = formData;
    const newErrors = {};

    if (!title.trim()) {
      newErrors.title = t('errors.titleRequired', 'Title is required.');
    }
    if (!description.trim()) {
      newErrors.description = t('errors.descriptionRequired', 'Description is required.');
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setLoading(true);
    const formPayload = new FormData();
    Object.keys(formData).forEach((key) => {
      formPayload.append(key, formData[key]);
    });

    try {
      const response = await postApi(`admin/update-news/${news.id}`, formPayload, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (response.success) {
        showToast('success', t('success.newsUpdated', 'News item updated successfully.'));
        navigate('/admin/news');
      }
    } catch (err) {
      showToast('error', err.message || t('errors.newsUpdateFailed', 'Failed to update news.'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form-wrapper">
      <div className="auth-container container">
        <div className="row">
          <div className="col-lg-12">
            <div className="card form-card p-4">
              <h2 className="text-primary mb-4">{t('adminNewsEdit.heading', 'Edit News')}</h2>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  {/* Title */}
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label text-dark">
                        {t('adminNewsEdit.fields.title', 'Title')}
                      </label>
                      <input
                        type="text"
                        name="title"
                        className="form-control border-0 bg-light px-4"
                        value={formData.title}
                        onChange={handleChange}
                      />
                      {errors.title && <small className="text-danger">{errors.title}</small>}
                    </div>
                  </div>

                  {/* Image */}
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label text-dark">
                        {t('adminNewsEdit.fields.image', 'Image')}
                      </label>
                      <input
                        type="file"
                        name="image"
                        className="form-control border-0 bg-light px-4"
                        onChange={handleChange}
                      />
                      {imagePreview && (
                        <div className="mt-3">
                            <figure className='preview-image'>
                          <img
                            src={imagePreview}
                            alt={t('adminNewsEdit.fields.imagePreview', 'Image Preview')}
                            className="img-thumbnail"
                            style={{ maxHeight: '200px' }}
                          />
                    
                          </figure>
                        </div>
                      )}
                      {errors.image && <small className="text-danger">{errors.image}</small>}
                    </div>
                  </div>

                  {/* Video Link */}
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label text-dark">
                        {t('adminNewsEdit.fields.videoLink', 'Video Link')}
                      </label>
                      <input
                        type="text"
                        name="video"
                        className="form-control border-0 bg-light px-4"
                        value={formData.video}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  {/* Description */}
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label text-dark">
                        {t('adminNewsEdit.fields.newsDescription', 'Description')}
                      </label>
                      <textarea
                        name="description"
                        className="form-control border-0 bg-light px-4"
                        rows="5"
                        value={formData.description}
                        onChange={handleChange}
                      />
                      {errors.description && <small className="text-danger">{errors.description}</small>}
                    </div>
                  </div>

                  {/* Submit Button */}
                  <div className="col-lg-12">
                    <button
                      type="submit"
                      className="btn btn-secondary py-3 px-5 mt-3"
                      disabled={loading}
                    >
                      {loading ? t('buttons.saving', 'Saving...') : t('buttons.submit', 'Submit')}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminEditNewsForm;