import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';
import { postApi } from '../../utils/api/api';
import { showToast } from '../../utils/toast';

const AdminTestimonialCreateForm = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    designation: '',
    image: null, // Single file input
    review: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null); // Preview for the image

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'image') {
      const file = files[0];
      setFormData((prev) => ({ ...prev, image: file }));
      setErrors((prev) => ({ ...prev, image: '' })); // Clear image-specific error

      // Update image preview
      if (file) {
        const reader = new FileReader();
        reader.onload = () => setImagePreview(reader.result);
        reader.readAsDataURL(file);
      } else {
        setImagePreview(null);
      }
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
      setErrors((prev) => ({ ...prev, [name]: '' })); // Clear field-specific errors
    }
  };

  const handleImageDelete = () => {
    setFormData((prev) => ({ ...prev, image: null }));
    setImagePreview(null);
  };

  const validateForm = () => {
    const { name, review } = formData;
    const newErrors = {};

    if (!name.trim()) {
      newErrors.name = t('errors.nameRequired', 'Title is required.');
    }
    if (!review.trim()) {
      newErrors.review = t('errors.reviewRequired', 'Description is required.');
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setLoading(true);
    const formPayload = new FormData();
    Object.keys(formData).forEach((key) => {
      formPayload.append(key, formData[key]);
    });

    try {
      const response = await postApi('admin/create-testimonial', formPayload, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (response.success) {
        showToast('success', t('success.newsCreated', 'News item created successfully.'));
        navigate('/admin/testimonials');
      }
    } catch (err) {
      showToast('error', err.message || t('errors.newsCreationFailed', 'Failed to create news.'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form-wrapper">
      <div className="auth-container container">
        <div className="row">
          <div className="col-lg-12">
            <div className="card form-card p-4">
              <h2 className="text-primary mb-4">{t('adminTestimonialCreate.heading', 'Create News')}</h2>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  {/* Title */}
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label text-dark">
                        {t('adminTestimonialCreate.fields.name', 'Title')}
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="form-control border-0 bg-light px-4"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      {errors.name && <small className="text-danger">{errors.name}</small>}
                    </div>
                  </div>
                  {/* Video Link */}
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label text-dark">
                        {t('adminTestimonialCreate.fields.designation', 'Video Link')}
                      </label>
                      <input
                        type="text"
                        name="designation"
                        className="form-control border-0 bg-light px-4"
                        value={formData.designation}
                        onChange={handleChange}
                      />
                      {errors.designation && <small className="text-danger">{errors.designation}</small>}
                    </div>
                  </div>

                  {/* Image */}
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label text-dark">
                        {t('adminTestimonialCreate.fields.image', 'Image')}
                      </label>
                      <input
                        type="file"
                        name="image"
                        className="form-control border-0 bg-light px-4"
                        accept="image/*"
                        onChange={handleChange}
                      />
                      {errors.image && <small className="text-danger">{errors.image}</small>}
                      {imagePreview && (
                        <div className="mt-3 hide-del-icon">
                          <figure className='preview-image'>
                          <img
                            src={imagePreview}
                            alt="Preview"
                            className="img-thumbnail"
                            style={{ maxWidth: '200px' }}
                          />
                          <button
                            type="button"
                            className="btn btn-link text-danger"
                            onClick={handleImageDelete}
                          >
                            <i class="fa fa-trash" aria-hidden="true"></i>
                          </button>
                          </figure>
                        </div>
                      )}
                    </div>
                  </div>

                  

                  {/* Description */}
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label text-dark">
                        {t('adminTestimonialCreate.fields.review', 'Description')}
                      </label>
                      <textarea
                        name="review"
                        className="form-control border-0 bg-light px-4"
                        rows="5"
                        value={formData.review}
                        onChange={handleChange}
                      />
                      {errors.review && <small className="text-danger">{errors.review}</small>}
                    </div>
                  </div>

                  {/* Submit Button */}
                  <div className="col-lg-12">
                    <button
                      type="submit"
                      className="btn btn-secondary py-3 px-5 mt-3"
                      disabled={loading}
                    >
                      {loading ? t('buttons.saving', 'Saving...') : t('buttons.submit', 'Submit')}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminTestimonialCreateForm;