import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useLanguage } from "../../contexts/LanguageContext";
import { showToast } from "../../utils/toast";

const UserDetailInfo = () => {
  const { id } = useParams(); // Extract user ID from the route parameters
  const location = useLocation(); // Access location state
  const { t } = useLanguage(); // For translations
  const user = location.state?.user || null; // Get user data from location state

  useEffect(() => {
    if (!user) {
      showToast("error", t("userDetail.userNotFound", "User not found!"));
    }
  }, [user, t]);

  if (!user) return <div>{t("userDetail.userNotFound", "User not found!")}</div>;

  return (
    <div className="container py-5">
      <h2 className="text-primary mb-4">
        {t("userDetail.heading", "User Details")}
      </h2>
      <div className="card shadow-sm">
        <div className="card-body">
          <h4 className="card-title mb-4">{user.name}</h4>
          <ul className="list-unstyled">
            <li>
              <strong>{t("userDetail.email", "Email:")}</strong> {user.email}
            </li>
            <li>
              <strong>{t("userDetail.phone", "Phone Number:")}</strong>{" "}
              {user.phone_number || t("userDetail.notAvailable", "N/A")}
            </li>
            <li>
              <strong>{t("userDetail.companyName", "Company Name:")}</strong>{" "}
              {user.c_name || t("userDetail.notAvailable", "N/A")}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UserDetailInfo;