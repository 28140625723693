import React from 'react';
import DashboardSidebar from '../components/DashboardComponents/DashboardSidebar';
import InnerBanner from '../components/PagesComponents/InnerBanner';
import AdminTestimonialDetailInfo from '../components/AdminComponents/AdminTestimonialDetailInfo';
const AdminTestimonialDetail = () => {
 return (
    <>
<InnerBanner pageKey="adminTestimonialDetail" />

      <section className='dashboard py-4'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-9'>
                   <AdminTestimonialDetailInfo/> 
                </div>
                <div className='col-lg-3'>
                    <DashboardSidebar/>
                </div>
            </div>
        </div>
      </section>
    </>
 );

};

export default AdminTestimonialDetail;