import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Pagination from '../../utils/pagination';
import { getApi, deleteApi } from '../../utils/api/api';
import { showToast } from '../../utils/toast';
import { showConfirmationAlert } from '../../utils/confirmationAlert'; // Assuming confirmation alert utility exists
import { useLanguage } from '../../contexts/LanguageContext';
import Loader from '../../utils/loader';

const AdminTestimonialList = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const [testimonialData, setNewsData] = useState({
    data: [],
    total: 0,
    per_page: 9,
    current_page: 1,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const baseUrl = "https://backend.tokoyarn.com/public/";

  const fetchNews = async (page = 1) => {
    setLoading(true);
    try {
      const response = await getApi(`admin/testimonial?page=${page}`);
      console.log("Testimonial response::", response);
      if (response.data && response.data.testimonial) {
        setNewsData({
          data: response.data.testimonial.data,
          total: response.data.testimonial.total,
          per_page: response.data.testimonial.per_page || 9,
          current_page: response.data.testimonial.current_page,
        });
      } else {
        setNewsData({ data: [], total: 0, per_page: 9, current_page: 1 });
      }
    } catch (error) {
      showToast('error', error.message || 'Failed to fetch news.');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    showConfirmationAlert({
      message: t('adminNews.confirmDelete', 'Are you sure you want to delete this news item?'),
      onConfirm: async () => {
        try {
          await deleteApi(`admin/news-testimonal/${id}`);
          showToast('success', t('adminNews.deleteSuccess', 'News item deleted successfully.'));
          setNewsData((prevState) => ({
            ...prevState,
            data: prevState.data.filter((item) => item.id !== id),
          }));
        } catch (error) {
          showToast('error', error.message || t('adminNews.deleteError', 'Failed to delete news item.'));
        }
      },
      onCancel: () => console.log('Delete canceled'),
    });
  };

  useEffect(() => {
    fetchNews(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => setCurrentPage(page);

  const totalPages = Math.ceil(testimonialData.total / testimonialData.per_page);

  return (
    <div className="container py-5">
        <div className='com-header d-flex justify-content-between align-items-center mb-4'>
        <h2 className="text-primary">{t('adminTestimonial.heading', 'News List')}</h2>
        <Link to="/admin/testimonials/create"
                className="btn btn-secondary py-3 px-5">
                {t("adminTestimonial.createTestimonial", "Create Testimonial")}
              </Link>
        </div>
      
        {loading ? (
        <Loader/>
      ) : testimonialData.data.length > 0 ? (
        <>
          <div className="row">
            {testimonialData.data.map((testimonial) => (
              <div className="col-md-12 mb-4" key={testimonial.id}>
                <div className="card p-3 shadow-sm">
                  <div className="row">
                    <div className="col-lg-4">
                      {testimonial.image ? (
                        <img
                          src={`${baseUrl}${testimonial.image}`}
                          alt={testimonial.title}
                          className="img-fluid"
                        />
                      ) : (
                        <p>{t('adminNews.noImage', 'No image available')}</p>
                      )}
                    </div>
                    <div className="col-lg-8">
                      <h4>{testimonial.name}</h4>
                      <p>{testimonial.review}</p>
                      {testimonial.video_link && (
                        <p>
                          <strong>{t('adminNews.videoLink', 'Video Link:')}</strong>{' '}
                          <a href={testimonial.video_link} target="_blank" rel="noopener noreferrer">
                            {t('adminNews.watchVideo', 'Watch Video')}
                          </a>
                        </p>
                      )}
                      <div className="d-flex">
                        <button
                          className="btn btn-danger mr-2"
                          onClick={() => handleDelete(testimonial.id)}
                        >
                          {t('adminNews.delete', 'Delete')}
                        </button>
                        <button
                          className="btn btn-primary mr-2"
                          onClick={() =>
                            navigate(`/admin/testimonials/edit/${testimonial.id}`, {
                              state: { testimonial },
                            })
                          }
                        >
                          {t('adminNews.edit', 'Edit')}
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            navigate(`/admin/testimonials/${testimonial.id}`, {
                              state: { testimonial },
                            })
                          }
                        >
                          {t('adminNews.viewDetails', 'View Details')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Pagination
            currentPage={testimonialData.current_page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </>
      ) : (
        <p>{t('adminNews.noNews', 'No news found.')}</p>
      )}
    </div>
  );
};

export default AdminTestimonialList;