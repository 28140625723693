import React from 'react';
import { useLocation } from 'react-router-dom';

const AdminNewsDetailInfo = () => {
  const location = useLocation();
  const news = location.state?.news || null; // Access the selected news item

  if (!news) return <div>News item not found!</div>;

  const baseUrl = "https://backend.tokoyarn.com/public/";

  return (
    <div className="container py-5">
      <div className="com-header mb-4">
        <h2 className="text-primary">News Details</h2>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card p-3 shadow-sm">
            <div className="row">
              {/* News Image */}
              <div className="col-lg-4">
                {news.image ? (
                  <img
                    src={`${baseUrl}${news.image}`}
                    alt={news.title}
                    className="img-fluid"
                  />
                ) : (
                  <p>No image available</p>
                )}
              </div>
              {/* News Details */}
              <div className="col-lg-8">
                <h4>{news.title}</h4>
                <p>{news.description}</p>
                {news.video_link && (
                  <p>
                    <strong>Video Link:</strong>{' '}
                    <a href={news.video_link} target="_blank" rel="noopener noreferrer">
                      Watch Video
                    </a>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminNewsDetailInfo;