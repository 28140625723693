import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLanguage } from './LanguageContext';
import { postApi } from '../utils/api/api';
import { showToast } from '../utils/toast';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { t = (key) => key } = useLanguage(); // Fallback to key if t is undefined
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [error, setError] = useState('');
  console.log("updatedUser::", user);

  const login = async (email, password, role) => {
    try {
      const response = await postApi('/login', { email, password, role });
      console.log('response:: ', response);
      const userData = {
        user_data: response.data.user,
        role: response.data.user.role,
        token: response.data.token,
      };
      setUser(userData);
      localStorage.setItem('user', JSON.stringify(userData));
      return { data: response };
    } catch (err) {
      setError(err.message || t('errors.loginFailed'));
    }
  };
  const adminLogin = async (email, password, role) => {
    try {
      const response = await postApi('/admin-login', { email, password, role});
      const adminData = {
        user_data: response.data.admin,
        role: 'admin',
        token: response.data.token,
      };
      setUser(adminData);
      localStorage.setItem('user', JSON.stringify(adminData));
      return { data: response };
    } catch (err) {
      setError(err.message || t('errors.adminLoginFailed', 'Admin login failed.'));
      throw err; // Ensure the error is propagated for the form to handle
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
    showToast('success', t('success.logOut', 'You have successfully logged out.'));
  };

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, login, adminLogin, logout, error }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);