import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Pagination from '../../utils/pagination';
import { useLanguage } from '../../contexts/LanguageContext';
import { getApi } from '../../utils/api/api'; // Update with your actual API utility
import Loader from '../../utils/loader';

const UsersList = () => {
    const navigate = useNavigate();
  const { t } = useLanguage(); // For translations
  const [loading, setLoading] = useState(false);
  const [usersData, setUsersData] = useState({
    data: [],
    total: 0,
    per_page: 9,
    current_page: 1,
  });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchUsers = async (page = 1) => {
      setLoading(true); // Start the loader
      try {
        const response = await getApi(`admin/users?page=${page}`);
        console.log("GetUser::", response.data);
  
        // Check if response contains the expected data structure
        if (response.data && response.data.users) {
          setUsersData({
            data: response.data.users.data, // User list
            total: response.data.users.total, // Total users
            per_page: response.data.users.per_page, // Items per page
            current_page: response.data.users.current_page, // Current page
          });
        } else {
          console.error("Unexpected API response:", response);
          setUsersData({ data: [], total: 0, per_page: 9, current_page: 1 });
        }
      } catch (error) {
        console.error("Failed to fetch users:", error);
        setUsersData({ data: [], total: 0, per_page: 9, current_page: 1 });
      } finally {
        setLoading(false); // Stop the loader
      }
    };
  
    fetchUsers(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container py-5">
      <h2 className="text-primary mb-4">
        {t('usersList.heading', 'Users List')}
      </h2>
      {loading ? (
        <Loader/>
      ) :
       usersData.data.length > 0 ? (
        <>
          <table className="table table-striped table-bordered">
            <thead className="table-dark">
              <tr>
                <th>#</th>
                <th>{t('usersList.name', 'Name')}</th>
                <th>{t('usersList.companyName', 'Company Name')}</th>
                <th>{t('usersList.phone', 'Phone Number')}</th>
                <th>{t('usersList.email', 'Email')}</th>
                <th>{t('usersList.actions', 'Actions')}</th>
              </tr>
            </thead>
            <tbody>
              {usersData.data.map((user, index) => (
                <tr key={user.id}>
                  <td>{(usersData.current_page - 1) * usersData.per_page + index + 1}</td>
                  <td>{user.name}</td>
                  <td>{user.c_name}</td>
                  <td>{user.phone_number || 'N/A'}</td>
                  <td>{user.email}</td>
                  <td>
                  <button
                        className="btn btn-primary btn-sm"
                        onClick={() => navigate(`/admin/users/${user.id}`, { state: { user } })}
                    >
                        <i className="far fa-eye"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            currentPage={usersData.current_page}
            totalPages={Math.ceil(usersData.total / usersData.per_page)}
            onPageChange={handlePageChange}
          />
        </>
      ) : (
        <p>{t('usersList.noUsers', 'No users found.')}</p>
      )}
    </div>
  );
};

export default UsersList;