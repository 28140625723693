import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getApi } from "../utils/api/api"; // Update with your actual API utility
import MainLayout from "../components/Layouts/MainLayout";
import InnerBanner from "../components/PagesComponents/InnerBanner";

const DEFAULT_IMAGE = "/images/default-news-image.jpg"; // Path to your default image

const News = () => {
  const baseUrl = "https://backend.tokoyarn.com/public/";
  const [newsData, setNewsData] = useState({
    data: [], // Ensure data is always initialized as an array
    total: 0,
    per_page: 9,
    current_page: 1,
  });
  const [currentPage, setCurrentPage] = useState(1);

  // Fetch news data from the API
  useEffect(() => {
    const fetchNews = async (page = 1) => {
      try {
        const response = await getApi(`/news?page=${page}`); // Update API endpoint if necessary
        console.log("News Response:", response.data.news);

        if (response.data && response.data.news) {
          setNewsData({
            data: response.data.news || [], // Ensure data is an array
            total: response.data.news.total || 0, // Total news count
            per_page: response.data.news.per_page || 9, // News per page
            current_page: response.data.news.current_page || 1, // Current page
          });
        } else {
          console.error("Unexpected API response:", response);
          setNewsData({ data: [], total: 0, per_page: 9, current_page: 1 });
        }
      } catch (error) {
        console.error("Failed to fetch news:", error);
        setNewsData({ data: [], total: 0, per_page: 9, current_page: 1 });
      }
    };

    fetchNews(currentPage);
  }, [currentPage]);

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil((newsData.total || 0) / newsData.per_page);

  return (
    <MainLayout>
      <InnerBanner pageKey="news" />
      <section className="news-section py-5">
        <div className="container">
          <h2 className="text-center mb-4">Latest News</h2>
          <div className="row">
            {newsData.data && newsData.data.length > 0 ? (
              newsData.data.map((news, index) => (
                <div className="col-md-4 mb-4" key={index}>
                  <div className="card h-100">
                    <img
                      src={`${baseUrl}${news.image}` || DEFAULT_IMAGE} // Use default image if no image is provided
                      className="card-img-top"
                      alt={news.title || "News Image"}
                    />
                    <div className="card-body">
                      <h5 className="card-title">{news.title}</h5>
                      <p className="card-text">{news.description}</p>
                      <Link
                        to={{
                          pathname: `/news-detail/${news.id}`,
                          state: { news }, // Pass the news object in state
                        }}
                        className="btn btn-primary"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center">No news available at the moment.</p>
            )}
          </div>

          {/* Pagination */}
          {totalPages > 1 && (
            <nav aria-label="News pagination">
              <ul className="pagination justify-content-center">
                {[...Array(totalPages)].map((_, i) => (
                  <li
                    key={i}
                    className={`page-item ${
                      newsData.current_page === i + 1 ? "active" : ""
                    }`}
                    onClick={() => handlePageChange(i + 1)}
                  >
                    <button className="page-link">{i + 1}</button>
                  </li>
                ))}
              </ul>
            </nav>
          )}
        </div>
      </section>
    </MainLayout>
  );
};

export default News;