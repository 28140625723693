import React from 'react';
import { useLocation, Link } from "react-router-dom";
import { useLanguage } from '../contexts/LanguageContext'; // Import the language context
import MainLayout from '../components/Layouts/MainLayout';
import BannerImg from '../assets/main-banner.png';
// import TestimonialSlider from '../components/PagesComponents/TestimonialSlider';
import TestimonialSlider from '../components/PagesComponents/Testimonials';
import WhatWeOffers from '../components/PagesComponents/WhatWeOffers';
import { translations } from "../i18n"; // Import translations

const Home = () => {
  const { language } = useLanguage(); // Get current language
  const homeData = translations[language]?.home; // Fetch homepage content based on language

  if (!homeData) {
    return <p>Loading...</p>; // Fallback in case content isn't loaded
  }

  return (
    <MainLayout>
      {/* Hero Banner */}
      <section className='hero-banner'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6 py-4'>
              <div className="p-3">
                <h5 className="text-white text-uppercase mb-3 animated slideInDown">{homeData.welcome}</h5>
                <h1 className="display-4 text-white mb-md-4 animated zoomIn">{homeData.heroTitle}</h1>
                <p className="text-white mb-md-4">{homeData.heroDescription}</p>
              </div>
            </div>
            <div className='col-lg-6'>
              <img src={BannerImg} alt='Hero Banner' />
            </div>
          </div>
        </div>
      </section>

      {/* What We Offer Section */}
      <WhatWeOffers />

      {/* Why Choose Us Section */}
      <section className='Why-choose-sec'>
        <div className='container'>
          <div className="section-title text-left position-relative pb-0 mb-3 mx-auto">
            <h2 className="mb-0 display-5">{homeData.whyChooseTitle}</h2>
          </div>

          <ul className='cstm-list'>
            {homeData.whyChooseUs.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      </section>

      {/* Get Started Section */}
      <section className='Why-choose-sec'>
  <div className='container'>
    <div className="section-title text-left position-relative pb-0 mb-3 mx-auto">
      <h2 className="mb-0 display-5">{homeData.getStartedTitle}</h2>
    </div>

    {/* Dynamically split description and insert the Link */}
    <h6>
      {homeData.getStartedDescription.split(homeData.registerLinkText)[0]}
      <Link to="/register">{homeData.registerLinkText}</Link>
      {homeData.getStartedDescription.split(homeData.registerLinkText)[1]}
    </h6>
  </div>
</section>

      {/* Testimonial Slider */}
      <TestimonialSlider />
    </MainLayout>
  );
};

export default Home;