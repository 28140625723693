import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../utils/pagination';
import { getApi } from '../../utils/api/api';
import { showToast } from '../../utils/toast';
import { useLanguage } from '../../contexts/LanguageContext';
import Loader from '../../utils/loader';

const SellerBidList = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const [bidsData, setBidsData] = useState({
    data: [],
    total: 0,
    per_page: 9,  // 9 products per page
    current_page: 1,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const baseUrl = "https://backend.tokoyarn.com/public/";
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProducts = async (page = 1) => {
      setLoading(true);
      try {
        const response = await getApi(`seller/on-product-bid?page=${page}`);
        console.log("GetBid::", response.data);

        if (response.data && response.data.bid) {
          setBidsData({
            data: response.data.bid.data,
            total: response.data.bid.total,
            per_page: response.data.bid.per_page || 9,
            current_page: response.data.bid.current_page,
          });
        } else {
          setBidsData({ data: [], total: 0, per_page: 9, current_page: 1 });
        }
      } catch (error) {
        showToast('error', error.message || 'Failed to fetch bids.');
        setBidsData({ data: [], total: 0, per_page: 9, current_page: 1 });
      } finally {
        setLoading(false);
      }
    };

    fetchProducts(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => setCurrentPage(page);

  const totalPages = Math.ceil(bidsData.total / bidsData.per_page);

  return (
    <div className="container py-5">
      <h2 className="text-primary mb-4">
        {/* {t('sellerBidList.heading', 'All Products')} */}
        Bid List
      </h2>

      {loading ? (
        <Loader/>
      ) : bidsData.data.length > 0 ? (
        <>
          <div className="row">
            {bidsData.data.map((bid) => (
              <div className="col-md-12 mb-4" key={bid.id}>
                <div className="card p-3">
                  <div className="row">
                    <div className="col-lg-4">
                      {bid.images && bid.images.length > 0 ? (
                        <img
                          src={`${baseUrl}${JSON.parse(bid.images)[0]}`}
                          alt={bid.name}
                          className="img-fluid"
                        />
                      ) : (
                        <p>No image available</p>
                      )}
                    </div>
                    <div className="col-lg-8">
                      <h4>{bid.name}</h4>
                      <p>Price: {bid.price}</p>
                      <p>Description: {bid.description}</p>
                      <div className="action-btn1">
                        <button
                          className="btn btn-secondary mb-2"
                          onClick={() =>
                            navigate(`/seller-dashboard/seller-bids/${bid.id}`, {
                              state: { bid }
                            })}
                        >
                          View Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <Pagination
            currentPage={bidsData.current_page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </>
      ) : (
        <p>{t('allProducts.noProducts', 'No products found.')}</p>
      )}
    </div>
  );
};

export default SellerBidList;