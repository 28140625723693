import React, { useState } from 'react';
import { showToast } from '../../utils/toast';
import { useLanguage } from "../../contexts/LanguageContext";
import { postApi } from '../../utils/api/api';

const PasswordUpdateForm = () => {
  const { t } = useLanguage();
  const [formData, setFormData] = useState({
    c_password: '',
    new_password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors((prev) => ({ ...prev, [name]: '' })); // Clear errors on input change
  };

  const validateForm = () => {
    const { c_password, new_password, confirmPassword } = formData;
    const newErrors = {};

    // Current Password validation
    if (!c_password.trim()) {
      newErrors.c_password = t('errors.currentPasswordRequired', 'Current password is required.');
    }

    // New Password validation
    if (!new_password.trim()) {
      newErrors.new_password = t('errors.newPasswordRequired', 'New password is required.');
    } else if (new_password.length < 6) {
      newErrors.new_password = t('errors.shortPassword', 'New password must be at least 6 characters long.');
    }

    // Confirm Password validation
    if (!confirmPassword.trim()) {
      newErrors.confirmPassword = t('errors.confirmPasswordRequired', 'Confirm password is required.');
    } else if (confirmPassword !== new_password) {
      newErrors.confirmPassword = t('errors.passwordMismatch', 'Passwords do not match.');
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setLoading(true);
    try {
      await postApi('/change-password', formData); // Update password API endpoint
      showToast('success', t('success.passwordUpdated', 'Password updated successfully.'));
      setFormData({ c_password: '', new_password: '', confirmPassword: '' });
    } catch (err) {
      showToast('error', err.message || t('errors.passwordUpdateFailed', 'Password update failed.'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="password-update-form mt-5">
      <h4 className="text-dark">{t('profileForm.passwordChangeHeading', 'Password Change')}</h4>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="form-label text-dark">{t('profileForm.fields.currentPassword.label', 'Current Password')}</label>
          <input
            type="password"
            name="c_password"
            className="form-control border-0 bg-light px-4"
            value={formData.c_password}
            onChange={handleChange}
          />
          {errors.c_password && <small className="text-danger">{errors.c_password}</small>}
        </div>
        <div className="form-group">
          <label className="form-label text-dark">{t('profileForm.fields.newPassword.label', 'New Password')}</label>
          <input
            type="password"
            name="new_password"
            className="form-control border-0 bg-light px-4"
            value={formData.new_password}
            onChange={handleChange}
          />
          {errors.new_password && <small className="text-danger">{errors.new_password}</small>}
        </div>
        <div className="form-group">
          <label className="form-label text-dark">{t('profileForm.fields.confirmPassword.label', 'Confirm New Password')}</label>
          <input
            type="password"
            name="confirmPassword"
            className="form-control border-0 bg-light px-4"
            value={formData.confirmPassword}
            onChange={handleChange}
          />
          {errors.confirmPassword && <small className="text-danger">{errors.confirmPassword}</small>}
        </div>
        <div className="form-group">
          <button type="submit" className="btn btn-secondary py-3 px-5 mt-3" disabled={loading}>
            {loading ? t('buttons.saving', 'Saving...') : t('profileForm.submitButton', 'Update Password')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PasswordUpdateForm;