import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';

const LanguageSwitcher = () => {
  const { language, changeLanguage } = useLanguage();

  return (
    <select className='form-control' style={{ maxWidth: "80px" }} value={language} onChange={(e) => changeLanguage(e.target.value)}>
      <option value="en">English</option>
      <option value="es">Bahasa</option>
    </select>
  );
};

export default LanguageSwitcher;
