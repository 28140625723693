import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";// Include SweetAlert2 styles

// Function to show a confirmation alert for logout or delete
export const showConfirmationAlert = ({ message, onConfirm, onCancel }) => {
  Swal.fire({
    title: message,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#4CAF50",
    cancelButtonColor: "#f44336",
    confirmButtonText: "Yes",
    cancelButtonText: "No",
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm(); // Execute confirm action
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      onCancel(); // Execute cancel action
    }
  });
};