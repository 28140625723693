import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { getApi } from "../utils/api/api"; // Import the getApi utility
import MainLayout from "../components/Layouts/MainLayout";
import InnerBanner from "../components/PagesComponents/InnerBanner";

const NewsDetail = () => {
  const { id } = useParams(); // Get the ID from the URL
  const [newsItem, setNewsItem] = useState(null); // State to store news data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  // Fetch news item on mount
  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await getApi(`/news-detail/${id}`); // Fetch news data using getApi
        if (response.data && response.data.news) {
          setNewsItem(response.data.news); // Set the news item from response
        } else {
          setError("News item not found.");
        }
        setLoading(false); // Stop loading
      } catch (error) {
        console.error("Failed to fetch news:", error);
        setError("An error occurred while fetching the news.");
        setLoading(false); // Stop loading in case of error
      }
    };

    fetchNews();
  }, [id]); // Re-fetch when `id` changes

  if (loading) {
    return (
      <MainLayout>
        <InnerBanner pageKey="newsDetails" />
        <div className="container text-center py-5">
          <h1>Loading...</h1>
        </div>
      </MainLayout>
    );
  }

  if (error) {
    return (
      <MainLayout>
        <InnerBanner pageKey="newsDetails" />
        <div className="container text-center py-5">
          <h1>Error</h1>
          <p>{error}</p>
        </div>
      </MainLayout>
    );
  }

  if (!newsItem) {
    return (
      <MainLayout>
        <InnerBanner pageKey="newsDetails" />
        <div className="container text-center py-5">
          <h1>News Not Found</h1>
          <p>The article you're looking for doesn't exist or was removed.</p>
        </div>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <InnerBanner pageKey="newsDetails" />
      <section className="news-detail-section py-5">
        <div className="container">
          <h1 className="mb-4">{newsItem.title}</h1>
          <img
            src={`http://backend.tokoyarn.com/public/${newsItem.image}`} // Update with correct path
            alt={newsItem.title}
            className="img-fluid mb-4"
          />
          <p className="text-muted">{newsItem.description}</p>
          {newsItem.video && (
            <p>
              <Link to={newsItem.video} className="btn btn-primary" target="_blank" rel="noopener noreferrer">
                Watch Video
              </Link>
            </p>
          )}
        </div>
      </section>
    </MainLayout>
  );
};

export default NewsDetail;