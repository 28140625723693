import React, { useEffect, useState } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import { useLanguage } from "../contexts/LanguageContext";
import InnerBanner from "../components/PagesComponents/InnerBanner";
import ProductSlider from "../components/Common/ProductSlider";
import { showToast } from "../utils/toast";
import { getApi } from "../utils/api/api"; // Reuse the API utility function
import Pagination from "../utils/pagination"; // Reuse pagination component
import DashboardSidebar from "../components/DashboardComponents/DashboardSidebar";

const BuyerProductDetail = () => {
  const baseUrl = "https://backend.tokoyarn.com/public/";
  const { id } = useParams(); // Extract product ID from the route parameters
  const location = useLocation(); // Access location state
  const { language } = useLanguage(); // Get selected language
  const product = location.state?.product || null; // Get product data from location state
  const [bids, setBids] = useState([]);
  const [activeTab, setActiveTab] = useState("product-details"); // To manage the active tab state
  const [currentPage, setCurrentPage] = useState(1);
  const [bidsPerPage] = useState(5); // Number of bids per page

  useEffect(() => {
    if (!product) {
      showToast("error", "Product not found!"); // Show error if product is missing
    }
  }, [product]);

  useEffect(() => {
    if (product) {
      // Fetch bids from API when product is available
      fetchBids();
    }
  }, [product]);

  const fetchBids = async () => {
    try {
      const response = await getApi(`buyer/product-detail/${id}`);
      const data = response?.data || {};
      console.log("Bids::",response.data.product.bids);
      if (data.product.bids) {
        setBids(data.product.bids);
      }
    } catch (error) {
      showToast("error", "Failed to load bids!");
    }
  };

  // Function to format keys (e.g., created_at -> Create At)
  const formatKey = (key) => {
    return key
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastBid = currentPage * bidsPerPage;
  const indexOfFirstBid = indexOfLastBid - bidsPerPage;
  const currentBids = bids.slice(indexOfFirstBid, indexOfLastBid);

  // Create pagination items
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(bids.length / bidsPerPage); i++) {
    pageNumbers.push(i);
  }

  if (!product) return <div>Product not found!</div>;

  return (
    <>
      <InnerBanner pageKey="productDetails" />
      <section className="product-detail py-5">
        <div className="container">
        <div className="row">
          <div className="col-lg-8">
          <div className="row">
            {/* Left Column - Image Slider */}
            <div className="col-lg-5">
              {product.images && JSON.parse(product.images).length ? (
                <ProductSlider images={product.images} />
              ) : (
                <p>No images available</p>
              )}
            </div>

            {/* Right Column - Details */}
            <div className="col-lg-7">
              <h2>{product.name}</h2>
              <p>
                <strong>Price:</strong> {product.price}
              </p>
              <h4>Description:</h4>
              <p>{product.description}</p>
            </div>
          </div>

          {/* Tabs for Product Details and Bids */}
          <ul className="nav nav-tabs mt-4" id="productTabs" role="tablist">
            <li className="nav-item" role="presentation">
              <a
                className={`nav-link ${activeTab === "product-details" ? "active" : ""}`}
                id="product-details-tab"
                data-bs-toggle="tab"
                href="#product-details"
                role="tab"
                aria-controls="product-details"
                aria-selected={activeTab === "product-details"}
                onClick={() => setActiveTab("product-details")}
              >
                Product Details
              </a>
            </li>
          </ul>
          <div className="tab-content mt-3" id="productTabsContent">
            {/* Product Details Tab */}
            <div
              className={`tab-pane fade ${activeTab === "product-details" ? "show active" : ""}`}
              id="product-details"
              role="tabpanel"
              aria-labelledby="product-details-tab"
            >
              <div className="card p-4 bg-light mt-4">
                <h2 className="title mb-4">Product Details</h2>
                <table className="table table-bordered">
                <tbody>
  {Object.entries(product).map(([key, value]) => {
    // Skip keys: images, id, user_id, created_at, updated_at
    if (key === "images" || key === "id" || key === "user_id" || key === "created_at" || key === "updated_at") {
      return null;
    }

    // Skip rendering if value is null, undefined, or an empty string
    if (value === null || value === undefined || value === "") {
      return null;
    }

    // Handle qty and qty_type concatenation under the key "quantity"
    if (key === "qty") {
      const qty = product.qty;
      const qtyType = product.qty_type;

      if (qty && qtyType) {
        const quantity = `${qty} ${qtyType}`;
        return (
          <tr key="quantity">
            <td>{formatKey("quantity")}</td>
            <td>{quantity}</td>
          </tr>
        );
      }
      return null; // Skip the row if qty or qty_type is missing
    }

    // Skip original `qty` and `qty_type` entries
    if (key === "qty_type") {
      return null;
    }

    // Skip if formatKey is null or empty
    const formattedKey = formatKey(key);
    if (!formattedKey) {
      return null;
    }

    // Handle certificate separately
    if (key === "certificate") {
      return (
        <tr key={key}>
          <td>{formattedKey}</td>
          <td>
            {value && value.length > 0 ? (
              <Link
                to={`${baseUrl}${value}`}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-secondary py-3 px-5"
              >
                Certificate
              </Link>
            ) : (
              <p>N/A</p>
            )}
          </td>
        </tr>
      );
    }

    // Render other rows if value is valid
    return (
      <tr key={key}>
        <td>{formattedKey}</td>
        <td>
          {typeof value === "object" && value !== null
            ? JSON.stringify(value)
            : value}
        </td>
      </tr>
    );
  })}
</tbody>
                </table>
              </div>
            </div>

            {/* Bids Tab */}
    
          </div>
          </div>
          <div className="col-lg-4">
            <DashboardSidebar/>
          </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BuyerProductDetail;