import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { getApi } from '../../utils/api/api';
import { useNavigate } from 'react-router-dom';
import Loader from '../../utils/loader';
import Pagination from '../../utils/pagination'; // Import the pagination component

const BuyerBidList = () => {
  const baseUrl = "https://backend.tokoyarn.com/public/";
  const { user } = useAuth();
  const navigate = useNavigate();
  const [bids, setBids] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalBids, setTotalBids] = useState(0);
  const [perPage] = useState(9); // Adjust per page items if needed

  // Fetch bids with pagination
  const fetchBids = async (page = 1) => {
    setLoading(true);
    try {
      const response = await getApi(`buyer/on-product-bid?page=${page}`);
      console.log('images response:: ', response);
      setBids(response.data.bid.data);
      setTotalBids(response.data.bid.total); // Set total bids for pagination
    } catch (err) {
      console.error('Failed to fetch bids.', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBids(currentPage);
  }, [currentPage]);

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  console.log("bids:::>>>>", bids.length > 0 ? typeof bids[0].product.images : 'No bids available');

  return (
    <div className="container py-5">
      <h2 className="text-primary mb-4">Buyer Bid List</h2>
      {loading ? (
        <Loader />
      ) : bids.length > 0 ? (
        <>
          <div className="row">
            {bids.map((bid) => (
              <div className="col-md-12 mb-4" key={bid.product?.id}>
                <div className="card p-3">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="image-grid">
                        {bid.product && bid.product.images && bid.product.images.length > 0 ? (
                          <img
                            key={0}
                            src={`${baseUrl + JSON.parse(bid.product.images)[0]}`}
                            alt={`Product ${0 + 1}`}
                            className="img-fluid mb-2"
                          />
                        ) : (
                          <p></p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-8">
                      {bid.product ? (
                        <>
                          <h4>{bid.product.name}</h4>
                          <div className="product-specs">
                            <p>Material: {bid.product.material}</p>
                            <p>Quantity: {bid.product.qty} {bid.product.qty_type}</p>
                            <p>Price: {bid.product.price}</p>
                            {bid.product.P1 && <p>P1: {bid.product.P1 || '-'}</p>}
                            {bid.product.P2 && <p>P2: {bid.product.P2 || '-'}</p>}
                            {bid.product.P3 && <p>P3: {bid.product.P3 || '-'}</p>}
                            {bid.product.P4 && <p>P4: {bid.product.P4 || '-'}</p>}
                            <p>Bid Amount: {bid.value}</p>
                          </div>
                          <p>Description: {bid.product.description}</p>
                        </>
                      ) : (
                        <p>Product details not available.</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(totalBids / perPage)}
            onPageChange={handlePageChange}
          />
        </>
      ) : (
        <p>No bids found.</p>
      )}
    </div>
  );
};

export default BuyerBidList;