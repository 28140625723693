// src/pages/About.jsx
import React from "react";
import MainLayout from "../components/Layouts/MainLayout";
import InnerBanner from "../components/PagesComponents/InnerBanner";
import AboutUs from "../components/PagesComponents/AboutUs";
import AboutUs2 from "../components/PagesComponents/AboutUs2";
import { useLanguage } from "../contexts/LanguageContext"; // Import Language Context
import { translations } from "../i18n"; // Import Translations
import importImage from "../utils/images/importImage";
import icon1 from "../assets/icons/aim.png";
import icon2 from "../assets/icons/deadline.png";
import icon3 from "../assets/icons/intelligence.png";
import icon4 from "../assets/icons/save.png";

const About = () => {
  const secImage3 = importImage("section-image3.jpg");
  const secImage4 = importImage("section-image4.jpg");
  const { language } = useLanguage(); // Get the current language
  const aboutData = translations[language]?.aboutData; // Fetch translations for "about"

  if (!aboutData) {
    return <p>Loading...</p>; // Fallback in case translations aren't loaded
  }

  return (
    <MainLayout>
      <InnerBanner pageKey="about" />
      <AboutUs/>
      <AboutUs2/>
      {/* Section 1: Purpose, Vision, Mission */}
      <section className="py-5">
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="mb-3">
                  <h5>{aboutData.purpose.title}</h5>
                  <p>{aboutData.purpose.content}</p>
                </div>
                <div className="mb-3">
                  <h5>{aboutData.vision.title}</h5>
                  <p>{aboutData.vision.content}</p>
                </div>
                <div className="mb-3">
                  <h5>{aboutData.mission.title}</h5>
                  <p>{aboutData.mission.content}</p>
                </div>
              </div>
              <div className="col-lg-5">
                <img
                  src={secImage3}
                  className="rounded"
                  data-wow-delay="0.9s"
                  style={{ objectFit: "cover", width: "100%", maxWidth: "500px" }}
                  alt={aboutData.altText}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section 2: Differentiators */}
      <section className="py-5">
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-7">
                <img
                  src={secImage4}
                  className="rounded"
                  data-wow-delay="0.9s"
                  style={{ objectFit: "cover", width: "100%", maxWidth: "500px" }}
                  alt={aboutData.altText}
                />
              </div>
              <div className="col-lg-5">
                <h5>{aboutData.differentiators.title}</h5>
                <ul className="cstm-list list-with-icon" style={{ listStyle: "none" }}>
                  {aboutData.differentiators.items.map((item, index) => (
                    <li key={index}>
                      <img
                        src={
                          index === 0
                            ? icon1
                            : index === 1
                            ? icon2
                            : index === 2
                            ? icon3
                            : icon4
                        }
                        className="rounded mr-2"
                        data-wow-delay="0.9s"
                        style={{ objectFit: "cover", width: "30px" }}
                        alt={item}
                      />
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

export default About;