import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';
import { useAuth } from '../../contexts/AuthContext';
import importImage from '../../utils/images/importImage';
import { showToast } from '../../utils/toast';

const AdminLoginForm = () => {
  const AdminLoginFormImage = importImage('login-form.jpg');
  const { t } = useLanguage();
  const { adminLogin } = useAuth(); // Admin-specific login function
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: '', password: '', role: 'admin' });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' })); // Clear errors on input change
  };

  const validateForm = () => {
    const { email, password } = formData;
    const newErrors = {};

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      newErrors.email = t('errors.emailRequired', 'Email is required.');
    } else if (!emailRegex.test(email)) {
      newErrors.email = t('errors.invalidEmail', 'Please enter a valid email address.');
    }

    if (!password.trim()) {
      newErrors.password = t('errors.passwordRequired', 'Password is required.');
    } else if (password.length < 6) {
      newErrors.password = t('errors.shortPassword', 'Password must be at least 6 characters long.');
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setLoading(true);
    try {
      const response = await adminLogin(formData.email, formData.password, formData.role);

      const admin = response?.data?.data?.user;
      console.log("admin::", response.data);
      const success = response?.data?.success;
      const message = response?.data?.message;

      if (success && admin) {
        showToast('success', message || t('login.success', 'Login successful!'));
        navigate('/admin/user-list', { replace: true });
      } else {
        throw new Error(t('login.error', 'Invalid response from server.'));
      }
    } catch (err) {
      showToast('error', err.message || t('login.error', 'Login failed.'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form-wrapper py-5">
      <div className="auth-container container">
        <div className="row">
          <div className="col-lg-6">
            <div className="card form-card p-4">
              <h2 className="text-primary mb-4">{t('login.adminHeading', 'Admin Login')}</h2>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label className="form-label text-dark">
                    {t('login.usernameLabel')}<sup>*</sup>
                  </label>
                  <input
                    className="form-control border-0 bg-light px-4"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && <small className="text-danger">{errors.email}</small>}
                </div>
                <div className="form-group">
                  <label className="form-label text-dark">
                    {t('login.passwordLabel', 'Password')}<sup>*</sup>
                  </label>
                  <input
                    className="form-control border-0 bg-light px-4"
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  {errors.password && <small className="text-danger">{errors.password}</small>}
                </div>
                <button
                  type="submit"
                  className="btn btn-secondary py-3 px-5 mt-3"
                  disabled={loading}
                >
                  {loading
                    ? t('buttons.loggingIn', 'Logging in...')
                    : t('buttons.login', 'Login')}
                </button>
              </form>
            </div>
          </div>
          <div className="col-lg-6">
            <img
              src={AdminLoginFormImage}
              alt={t('alt.adminLoginFormImage', 'Admin Login Form')}
              className="form-image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLoginForm;