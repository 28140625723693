import React from "react";
import { useLocation, Link } from "react-router-dom";
import { useLanguage } from "../../contexts/LanguageContext";
import { useAuth } from "../../contexts/AuthContext";
import { showConfirmationAlert } from "../../utils/confirmationAlert";

const DashboardSidebar = () => {
  const { t } = useLanguage();
  const location = useLocation();
  const { user, logout } = useAuth();

  
  const handleLogout = () => {
    showConfirmationAlert({
      message: t("dashboardSideNav.confirmLogout", "Are you sure you want to logout?"),
      onConfirm: () => {
        logout();
        console.log("Logged out successfully");
      },
      onCancel: () => {
        console.log("Logout cancelled");
      },
    });
  };

  // Determine dashboard type based on URL
  const isBuyerDashboard = location.pathname.startsWith("/buyer-dashboard");
  const isSellerDashboard = location.pathname.startsWith("/seller-dashboard");
  const isAdminDashboard = location.pathname.startsWith("/admin");

  return (
    <div className="dashboard-sidebar">
      <ul className="sidebar-menu-list">
      {isAdminDashboard && (
          <>
            <li>
              <Link
                to="/admin/user-list"
                className={`nav-item nav-link ${
                  location.pathname === "/admin/user-list" ? "active" : ""
                }`}
              >
                {t("dashboardSideNav.navMenu.adminUserList", "Admin Dashboard")}
              </Link>
            </li>
            <li>
              <Link
                to="/admin/products"
                className={`nav-item nav-link ${
                  location.pathname === "/admin/products" ? "active" : ""
                }`}
              >
                {t("dashboardSideNav.navMenu.adminProducts", "Products")}
              </Link>
            </li>
            <li>
              <Link
                to="/admin/approved-bids"
                style={{ pointerEvents: "none" }}
                className={`nav-item nav-link ${
                  location.pathname === "/admin/approved-bids" ? "active" : ""
                }`}
              >
                {t("dashboardSideNav.navMenu.approvedBids", "Approved Bids")}
              </Link>
            </li>
            <li>
              <Link
                to="/admin/news"
                className={`nav-item nav-link ${
                  location.pathname === "/admin/news" ? "active" : ""
                }`}
              >
                {t("dashboardSideNav.navMenu.news", "News")}
              </Link>
            </li>
            <li>
              <Link
                to="/admin/testimonials"
                className={`nav-item nav-link ${
                  location.pathname === "/admin/testimonials" ? "active" : ""
                }`}
              >
                {t("dashboardSideNav.navMenu.adminTestimonial", "Testimonials")}
              </Link>
            </li>
            <li>
              <Link
                to="/admin/payments"
                style={{ pointerEvents: "none" }}
                className={`nav-item nav-link ${
                  location.pathname === "/admin/payments" ? "active" : ""
                }`}
              >
                {t("dashboardSideNav.navMenu.payments", "Payments")}
              </Link>
            </li>
          </>
        )}
        {/* Buyer Dashboard Links */}
        {isBuyerDashboard && (
          <>
            <li>
              <Link
                to="/buyer-dashboard"
                className={`nav-item nav-link ${
                  location.pathname === "/buyer-dashboard" ? "active" : ""
                }`}
              >
                {t("dashboardSideNav.navMenu.buyerDashboard", "Buyer Dashboard")}
              </Link>
            </li>
            <li>
              <Link
                to="/buyer-dashboard/buyer-bids"
                className={`nav-item nav-link ${
                  location.pathname === "/buyer-dashboard/buyer-bids"
                    ? "active"
                    : ""
                }`}
              >
                {t("dashboardSideNav.navMenu.buyerBids", "Buyer Bids")}
              </Link>
            </li>
            {/* Uncomment if needed */}
            {/* <li>
              <Link
                to="/buyer-dashboard/special-feature"
                className={`nav-item nav-link ${
                  location.pathname === "/buyer-dashboard/special-feature"
                    ? "active"
                    : ""
                }`}
              >
                {t(
                  "dashboardSideNav.navMenu.buyerSpecialFeature",
                  "Special Feature"
                )}
              </Link>
            </li> */}
          </>
        )}

        {/* Seller Dashboard Links */}
        {isSellerDashboard && (
          <>
            <li>
              <Link
                to="/seller-dashboard"
                className={`nav-item nav-link ${
                  location.pathname === "/seller-dashboard" ? "active" : ""
                }`}
              >
                {t("dashboardSideNav.navMenu.sellerDashboard", "Seller Dashboard")}
              </Link>
            </li>
            <li>
              <Link
                to="/seller-dashboard/add-inventory"
                className={`nav-item nav-link ${
                  location.pathname === "/seller-dashboard/manage-products"
                    ? "active"
                    : ""
                }`}
              >
                {t("dashboardSideNav.navMenu.addInventory", "Add Inventory")}
              </Link>
            </li>
            <li>
              <Link
                to="/seller-dashboard/seller-bids"
                className={`nav-item nav-link ${
                  location.pathname === "/seller-dashboard/seller-bids"
                    ? "active"
                    : ""
                }`}
              >
                {t("dashboardSideNav.navMenu.sellerBids", "Seller Bids")}
              </Link>
            </li>
          </>
        )}

        {/* Role Link - Show only if not on Seller or Buyer Dashboard */}
        {!isSellerDashboard && !isBuyerDashboard && !isAdminDashboard && (
          <>
          <li>
            <Link
              to="/role"
              className={`nav-item nav-link ${
                location.pathname === "/role" ? "active" : ""
              }`}
            >
              {t("dashboardSideNav.navMenu.role", "Role")}
            </Link>
          </li>
          </>
        )}

        {/* Common Links */}
        {!isAdminDashboard && (
        <li>
          <Link
            to="/profile"
            className={`nav-item nav-link ${
              location.pathname === "/profile" ? "active" : ""
            }`}
          >
            {t("dashboardSideNav.navMenu.profile", "Profile")}
          </Link>
        </li>
         )}
        {/* Uncomment if needed */}
        {/* <li>
          <Link
            to="/orders"
            className={`nav-item nav-link ${
              location.pathname === "/orders" ? "active" : ""
            }`}
          >
            {t("dashboardSideNav.navMenu.orders", "Orders")}
          </Link>
        </li> */}
        <li>
          <Link
            to=""
            className="nav-item nav-link"
            onClick={handleLogout}
          >
            {t("dashboardSideNav.navMenu.logout", "Logout")}
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default DashboardSidebar;