import React, { useState, useEffect } from 'react';
import { useLanguage } from "../../contexts/LanguageContext";
import { useNavigate } from 'react-router-dom';
import { getApi, postApi } from '../../utils/api/api'; // Assuming getApi is used for fetching data
import { showToast } from '../../utils/toast';
import { useAuth } from '../../contexts/AuthContext';
import PasswordUpdateForm from './PasswordUpdateForm';

const ProfileForm = () => {
  const navigate = useNavigate();
  const { t } = useLanguage();
  const { user, setUser } = useAuth(); // Access and update user data from AuthContext
  console.log("AuthUser::", user);
  // Initialize form data
  const [formData, setFormData] = useState({
    name: '',
    c_name: '',
    c_type: '',
    email: '',
    phone_number: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  // Fetch profile data from API
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await getApi('/get-profile'); // API endpoint for fetching profile
        const { data } = response;
        console.log("Profile Form data::>", data);

        // Update form data and AuthContext
        setFormData({
          name: data.user.name || '',
          c_name: data.user.c_name || '',
          c_type: data.user.c_type || '',
          designation: data.user.designation || '',
          email: data.user.email || '',
          phone_number: data.user.phone_number || '',
        });
      } catch (err) {
        showToast('error', t('errors.fetchProfileFailed', 'Failed to fetch profile data.'));
      }
    };

    fetchProfileData();
  }, [setUser, t]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors((prev) => ({ ...prev, [name]: '' })); // Clear errors on input change
  };

  const validateForm = () => {
    const { name, c_name, c_type, designation } = formData;
    const newErrors = {};

    // Name validation
    if (!name.trim()) {
      newErrors.name = t('errors.nameRequired', 'Name is required.');
    }

    // Company Name validation
    if (!c_name.trim()) {
      newErrors.c_name = t('errors.companyNameRequired', 'Company name is required.');
    }

    // Company Type validation
    if (!c_type.trim()) {
      newErrors.c_type = t('errors.companyTypeRequired', 'Company type is required.');
    }

    // Designation validation
    if (!designation.trim()) {
      newErrors.designation = t('errors.designationRequired', 'Designation is required.');
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setLoading(true);
    try {
      const userData = await postApi('/profile', formData); // Update profile API endpoint
      console.log('userData:: ', userData);
      if (userData?.success) {
        const storedUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};
        if (Object.keys(storedUser).length) {
          storedUser.user_data = userData?.data?.user;
          localStorage.setItem('user', JSON.stringify(storedUser))
          setUser(storedUser);
        }
        showToast('success', t('success.profileUpdated', 'Profile updated successfully.'));
      }
    } catch (err) {
      showToast('error', err.message || t('errors.profileUpdateFailed', 'Profile update failed.'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="form-wrapper">
        <div className="auth-container container">
          <div className='row'>
            <div className='col-lg-12'>
              <div className='card form-card p-4'>
                <h2 className='text-primary mb-4'>{t('profileForm.heading', 'Edit Profile')}</h2>

                <form onSubmit={handleSubmit}>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <div className='form-group'>
                        <label className="form-label text-dark">{t('profileForm.fields.name.label', 'Name')}</label>
                        <input
                          type="text"
                          name="name"
                          className='form-control border-0 bg-light px-4'
                          value={formData.name}
                          onChange={handleChange}
                        />
                        {errors.name && <small className="text-danger">{errors.name}</small>}
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='form-group'>
                        <label className="form-label text-dark">{t('profileForm.fields.companyName.label', 'Company Name')}</label>
                        <input
                          type="text"
                          name="c_name"
                          className='form-control border-0 bg-light px-4'
                          value={formData.c_name}
                          onChange={handleChange}
                        />
                        {errors.c_name && <small className="text-danger">{errors.c_name}</small>}
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <div className='form-group'>
                        <label className="form-label text-dark">{t('profileForm.fields.companyType.label', 'Company Type')}</label>
                        <input
                          type="text"
                          name="c_type"
                          className='form-control border-0 bg-light px-4'
                          value={formData.c_type}
                          onChange={handleChange}
                        />
                        {errors.c_type && <small className="text-danger">{errors.c_type}</small>}
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='form-group'>
                        <label className="form-label text-dark">{t('profileForm.fields.email.label', 'Email')}</label>
                        <input
                          type="email"
                          name="email"
                          className='form-control border-0 bg-light px-4'
                          value={formData.email}
                          onChange={handleChange}
                          disabled
                        />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='form-group'>
                        <label className="form-label text-dark">{t('profileForm.fields.phone.label', 'Phone Number')}</label>
                        <input
                          type="text"
                          name="phone_number"
                          className='form-control border-0 bg-light px-4'
                          value={formData.phone_number}
                          onChange={handleChange}
                          disabled
                        />
                      </div>
                    </div>
                

                    <div className='col-lg-12'>
                      <button type="submit" className='btn btn-secondary py-3 px-5 mt-3' disabled={loading}>
                        {loading ? t('buttons.saving') : t('profileForm.submitButton')}
                      </button>
                    </div>
                  </div>
                </form>
                {/* Password Update Form */}
                <PasswordUpdateForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileForm;